new Vue({
  el: '#strap',
  data: function() {
    return {
      keywords: '',
      menu: {
        open: false,
      },
      search: {
        open: false,
      },
      socials: {
        open: false
      }
    }
  },
  methods: {
    toggleMenu() {
      this.menu.open = !this.menu.open;
    },
    toggleSearch() {
      this.search.open = !this.search.open;
    },
    toggleSocials() {
      this.socials.open = !this.socials.open;
    },
    searchSite() {
      window.location.href = '/?s=' + encodeURIComponent(this.keywords);
    },
  },
  created() {
    this.keywords = document.getElementById('strap-search').dataset.keywords;
  }
});